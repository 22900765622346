import * as validationMethods from '../validations';

export const getValidationMethod = (validation) => {
  const method = validationMethods[validation.type];
  if (method === undefined) return undefined;

  if (validation.value) return method(validation.value, validation.errorMessage);
  return method(validation.errorMessage);
};

export const getValidationsMethods = (validations) => {
  if (validations === undefined) return [];

  return validations.reduce((acc, validation) => {
    const method = getValidationMethod(validation);
    if (method) return [...acc, method];
    return acc;
  }, []);
};

export const getValidationFunction = (validations) => {
  if (!validations) return () => {};
  const validationFns = getValidationsMethods(validations);

  return (formValue, allFormValues) => {
    for (let i = 0; i < validationFns.length; i += 1) {
      const error = validationFns[i](formValue, allFormValues);
      if (error) return error;
    }
    return undefined;
  };
};
