import { t } from 'bv-i18n';
import postMobileNumberValidation from '../api/mobile_number_validation';

// Input object example:
// { number: '', country_iso: '' }

const formattedNumber = (phoneNumber) => `+${phoneNumber.dial_code}${phoneNumber.number}`;

const addNewComment = (currentComment, newComment, form) => {
  if (newComment && (!currentComment || currentComment.indexOf(newComment) === -1)) {
    const updatedComment = currentComment ? `${currentComment}; ${newComment}` : newComment;
    form.change('comment', updatedComment);
  }
};

export default (values, form) => {
  const { validatedPhoneNumber, phone_number: phoneNumber } = values;
  if (validatedPhoneNumber && validatedPhoneNumber.number === formattedNumber(phoneNumber)) {
    return Promise.resolve(validatedPhoneNumber);
  }

  return postMobileNumberValidation(phoneNumber.number, phoneNumber.country_iso).then(
    (response) => {
      form.change('validatedPhoneNumber', {
        number: formattedNumber(phoneNumber),
        ...response,
      });

      if (response.valid === true) {
        addNewComment(values.comment, response.comment, form);
        return Promise.resolve(response);
      }

      throw new Error(response.message || t('javascript.account_form.errors.general'));
    },
  );
};
